import React from "react";
import Player from "./object/Player";
import "./player.css"
import image from "./images/player.png";

let movementTickDelay = 10;

// -1 - NULL
// 0 - W
// 1 - A
// 2 - S
// 3 - D
let lastClick = -1;

//                  X, Y
//Pozycja startowa {1, 1}
let start = {
    x: 1,
    y: 1
}

//Lokalny gracz
let player = new Player(start);

//Lista graczy
let playerList = [
    player
]

class App extends React.Component {

    constructor() {
        super();

        this.state = {
            playerList: playerList
        }

        document.addEventListener("keydown", click => {
            let key = click.key;
            if (key === "w") {
                lastClick = 0;
            } else if (key === "a") {
                lastClick = 1;
            } else if (key === "s") {
                lastClick = 2;
            } else if (key === "d") {
                lastClick = 3;
            }
        });

        const tickDelay = 50;
        setInterval(() => {
            this.tick();
        }, tickDelay);
    }

    tick = () => {
        if(movementTickDelay <= 0){
            this.move(player, lastClick)
            lastClick = -1;
        }else{
            movementTickDelay--;
        }

        this.randomMove();

        this.setState({
            playerList: playerList
        })
    }

    move = (player, click) => {
        let directionX = 0;
        let directionY = 0;
        let directionFacing;

        if (click === 0) {
            directionY += 1;
            directionFacing = -192;
        } else if (click === 2) {
            directionY -= 1;
            directionFacing = -128;
        } else if (click === 3) {
            directionX += 1;
            directionFacing = 0;
        } else if (click === 1) {
            directionX -= 1;
            directionFacing = -64;
        }

        if (directionX === 0 && directionY === 0) {
            return;
        }

        movementTickDelay = 5;
        player.addXY({facing: directionFacing, x: directionX, y: directionY})
    }

    randomMove = () => {
        playerList.map(playerMap => {
            if (playerMap !== player) {
                this.move(playerMap, Math.floor(Math.random() * 4));
            }
        })
    }

    render() {
        return (
            <div className={"game-container"}>
                {
                    this.state.playerList.map(playerMap => {
                        return (
                            <div id={"player"} style={{
                                left: playerMap.x * 16,
                                bottom: playerMap.y * 16

                            }}>
                                <div id="playerSprite">
                                    <img id="playerImage" src={image} style={{
                                        top: playerMap.facing
                                    }}/>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}

export default App;