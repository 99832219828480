class Player {

    constructor({x, y}) {
        this.x = x;
        this.y = y;
    }

    addXY(direction) {
        this.x += direction.x;
        this.y += direction.y;
        this.facing = direction.facing;
    }
}

export default Player;